<template>
<div ref="page">
    <div class="star-box">
        <span>关注</span>
        <img src="../../assets/image/no-star.png" alt @click="changeStar" v-if="!baseData.star" />
        <img src="../../assets/image/star.png" alt @click="changeStar(false)" v-else />
    </div>
    <div class="tabs-anchor">
        <a class="anchor-item" @click="scrollIntoViewTo('#base')">基本信息</a>
        <!-- <a class="anchor-item" @click="scrollIntoViewTo('#gudong')">股东信息</a>
            <a class="anchor-item" @click="scrollIntoViewTo('#touzi')">对外投资</a> -->
    </div>
    <h4 class="base-title" id="base">基本信息</h4>
    <DownloadBtn :id="1" :type=1 :style="{
        position: 'relative',
        'margin-top': '20px',
        'margin-left': '20px',
        'background-color': 'rgba(241, 110, 113, 1)',
      }" @exportData="exportData"></DownloadBtn>
    <dataplat-base :formData="baseData"></dataplat-base>
    <!-- <div class="right-box">
            <h4 class="title" id="gudong">股东信息</h4>
            <dataplat-content
                :columns="gdxxList.tableColumns"
                :list="gdxxList.tableList"
                :total="gdxxList.total"
                @changePage="(page) => this.handleChangePage('gdxxList', page)"
                @changePageSize="(page) => this.handleChangePageSize('gdxxList', page)"
            ></dataplat-content>
            <h4 class="title" id="touzi">对外投资</h4>
            <dataplat-content
                :columns="dwtzList.tableColumns"
                :list="dwtzList.tableList"
                :total="dwtzList.total"
                @changePage="(page) => this.handleChangePage('dwtzList', page)"
                @changePageSize="(page) => this.handleChangePageSize('dwtzList', page)"
            ></dataplat-content>
        </div> -->
</div>
</template>

<script>
import Base from "./components/bankInformation/Base";
import ContentPanel from "@/components/ContentPanel";
import DownloadBtn from "@/components/DownloadBtn";
import {
    post_enterprise_yh_gsxx,
    post_enterprise_yh_gdxxList,
    post_enterprise_yh_dwtzList,
} from "@/api/bank";
import {
    post_enterprise_save
} from "@/api/houseEnterprise";
import {
    downloadData,
    formatJson
} from "@/utils/index";
import dataReports from "@/mixins/dataReports";

export default {
    name: "bankInformation",
    mixins: [dataReports],
    components: {
        "dataplat-base": Base,
        // 'dataplat-content': ContentPanel,
        DownloadBtn,
    },
    computed: {
        code() {
            return this.$store.state.componeyCode;
        },
    },
    data() {
        return {
            baseData: {},
            gdxxList: {
                searchForm: {
                    size: 10,
                    current: 1,
                },
                columns: [],
                total: 0,
                tableList: [],
            },
            dwtzList: {
                searchForm: {
                    size: 10,
                    current: 1,
                },
                columns: [],
                total: 0,
                tableList: [],
            },
        };
    },
    mounted() {
        if (this.$route.query.code) {
            this.$store.state.componeyCode = this.$route.query.code;
        }
        this.init();
    },
    watch: {
        code(newVal, oldVal) {
            if (newVal !== oldVal && this.$route.query.code) {
                this.$store.state.componeyCode = this.$route.query.code;
            }else{
                // this.$store.commit("setComponeyCode", '601398');
                this.$store.state.componeyCode = '601398'
            }
            this.init();
        },
    },
    methods: {
        init(type) {
            this.getInfo();
            this.getData("gdxxList");
            this.getData("dwtzList");
        },
        scrollIntoViewTo(id) {
            document.querySelector(id).scrollIntoView();
        },
        changeStar(status = true) {
            const type = this.$route.path.split("/")[3];

            const pathType = {
                houseEnterprise: {
                    qylx: "房企",
                    id: 5,
                },
                bank: {
                    qylx: "银行",
                    id: 6,
                },
            };
            const renderType = pathType[type].id;
            post_enterprise_save({
                state: status ? 1 : -1,
                unionId: this.baseData.id,
                type: renderType,
            }).then((res) => {
                this.baseData.star = !this.baseData.star;
            });
        },
        exportData() {
            const gdxxListColumns = [{
                    title: "序号",
                    key: "id",
                    type: "index",
                },
                {
                    title: "股东名称",
                    key: "gdmc",
                },
                {
                    title: "持股数(股)",
                    key: "gdbq",
                },
                {
                    title: "持股份额",
                    key: "cgbl",
                },
            ];

            const dwtzListColumns = [{
                    title: "持股企业/个人名称",
                    key: "zgsmc",
                },
                {
                    title: "持股份额",
                    key: "cgbl",
                },
                {
                    title: "认缴出资金额(万元)",
                    key: "rjcqze",
                },
            ];
            let filterVal1 = [];
            let filterVal2 = [];
            let tHeader1 = [];
            let tHeader2 = [];
            gdxxListColumns.forEach((item) => {
                tHeader1.push(item.title);
                filterVal1.push(item.key);
            });
            dwtzListColumns.forEach((item) => {
                tHeader2.push(item.title);
                filterVal2.push(item.key);
            });
            const data1 = formatJson(filterVal1, this.gdxxList.tableList);
            const data2 = formatJson(filterVal2, this.dwtzList.tableList);
            downloadData(tHeader1, data1, "股东信息");
            downloadData(tHeader2, data2, "对外投资");
        },
        getInfo(type) {
            if (!this.code) return;
            post_enterprise_yh_gsxx({
                    code: this.code,
                })
                .then((data) => {
                    this.baseData = data;
                })
                .catch((e) => {
                });
        },
        handleChangePage(type, page) {
            this[type].searchForm.current = page;
            this.getData(type);
        },
        handleChangePageSize(type, page) {
            this.searchForm.size = page;
            this[type].getData(type);
        },
        getData(type) {
            this[type].searchForm.code = this.code;
            let requestType = {
                gdxxList: post_enterprise_yh_gdxxList,
                dwtzList: post_enterprise_yh_dwtzList,
            };
            const gdxxListColumns = [{
                    title: "序号",
                    key: "id",
                    type: "index",
                },
                {
                    title: "股东名称",
                    key: "gdmc",
                },
                {
                    title: "持股数(股)",
                    key: "gdbq",
                },
                {
                    title: "持股份额",
                    key: "cgbl",
                },
            ];
            const dwtzListColumns = [{
                    title: "持股企业/个人名称",
                    key: "zgsmc",
                },
                {
                    title: "持股份额",
                    key: "cgbl",
                },
                {
                    title: "认缴出资金额(万元)",
                    key: "rjcqze",
                },
            ];
            let columns = {
                gdxxList: gdxxListColumns,
                dwtzList: dwtzListColumns,
            };
            requestType[type](this[type].searchForm)
                .then((data) => {
                    this[type].tableColumns = columns[type];
                    this[type].total = data.total;
                    this[type].tableList = data.records || [];
                })
                .catch((e) => {
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.star-box {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;

    span {
        margin-right: 10px;
    }
}

.tabs-anchor {
    background: rgba(252, 252, 255, 1);
    border: 1px solid rgba(214, 215, 217, 1);
    color: #666666;
    padding: 10px 0 0;
    display: flex;
    margin: 20px 20px 0;

    .anchor-item {
        color: #333;
        border-bottom: 3px solid #fff;
        padding: 0 30px 10px;
    }

    .anchor-item:hover {
        color: #00b6ff;
        border-bottom: 3px solid #00b6ff;
        padding: 0 30px 10px;
    }
}

.title {
    color: #1562d6;
    border-left: 2px solid #1562d6;
    line-height: 24px;
    padding-left: 10px;
    margin: 10px 0;
    font-size: 18px;
}

.base-title {
    color: #1562d6;
    border-left: 2px solid #1562d6;
    line-height: 24px;
    padding-left: 10px;
    margin: 20px 20px 0;
    font-size: 18px;
}
</style>
