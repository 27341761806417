var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"convertibleDetail"},[_c('div',[_c('div',{staticClass:"table-title"}),_vm._l((_vm.showList.even),function(item,index){return _c('cTableItem',{key:index,attrs:{"titleArr":[
        `${item.name}`,
        `${_vm.showList.odd[index] ? _vm.showList.odd[index].name : ''}`,
      ],"contentArr":[
        `${item.info}`,
        `${_vm.showList.odd[index] ? _vm.showList.odd[index].info : ''}`,
      ]}})}),_c('cTableItem',{attrs:{"titleArr":['公司网站'],"type":"one","itemHref":[_vm.formData.gswz],"contentArr":[_vm.formData.gswz]}}),_c('cTableItem',{attrs:{"titleArr":['经营范围'],"type":"one","contentArr":[_vm.formData.jyfw]}}),_c('cTableItem',{attrs:{"titleArr":['公司简介'],"type":"one","bigLine":true,"contentArr":[_vm.formData.gsjj]}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }